import React, {useState} from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {t} from 'ttag';

import Button from '../../../../../../components/button/button';
import Icons from '../../../../../../components/icons/icons.jsx';
import Editor from '../../editor/editor.jsx';
import {useAppContext} from '../../../../../../app-context.js';
import {useStaffContext} from '../../staff-context.js';
import {useRequest} from '../../../../../../common/hooks';
import {createEmployeeReq} from '../../../../../../common/api/api.js';
import {trimValues} from '../../../../../../common/actions.js';

import {fetchEmployees} from 'common/api/pipedrive';
import {updateEmployeeListReq as bitrix24UpdateEmployeeListReq} from '../../../../../../common/api/bitrix24.js';
import {updateEmployeeListReq as kommoUpdateEmployeeListReq} from '../../../../../../common/api/amo.js';
import {updateEmployeeListReq as mondayUpdateEmployeeListReq} from '../../../../../../common/api/monday.js';

const Creating = ({setVisibleEmployeeList, getEmployeeList}) => {
  const {user} = useAppContext();
  const {visibleEmployeeList, setEmployeeList} = useStaffContext();

  const [isOpen, setIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const {request, crmRequest} = useRequest();

  const handleSubmit = async values => {
    setIsOpen(false);

    const trimmedValues = trimValues(values);
    const res = await request(createEmployeeReq(user.id, trimmedValues));

    if (res.id) {
      setEmployeeList(prevValue =>
        update(prevValue, {
          $push: [res]
        })
      );
    }

    return true;
  };

  const handleRefresh = async () => {
    setIsFetching(true);

    const temp = JSON.parse(JSON.stringify(visibleEmployeeList));
    setVisibleEmployeeList();

    const req =
      user.crm == 'B24' || user.crm == 'TELPHGRAM'
        ? bitrix24UpdateEmployeeListReq
        : user.crm == 'AVITO' || user.crm == 'AMO' || user.crm == 'TELEGRAM'
          ? kommoUpdateEmployeeListReq
          : user.crm == 'MONDAY' || user.crm == 'TGMONDAY'
            ? mondayUpdateEmployeeListReq
            : user.crm == 'PIPEDRIVE'
              ? fetchEmployees
              : null;

    if (req) {
      const res = await crmRequest({
        crm: user.crm,
        ...req(user.domain)
      });

      if (res) {
        await new Promise(resolve =>
          setTimeout(resolve, user.crm == 'PIPEDRIVE' ? 1000 : 0)
        );

        await getEmployeeList();
        setIsFetching(false);
        return;
      }
    }

    setVisibleEmployeeList(temp);
    setIsFetching(false);
  };

  return (
    <>
      {user.crm === 'LK' ? (
        <Button onClick={() => setIsOpen(true)}>
          <Icons.Plus />
          {t`Add an employee`}
        </Button>
      ) : (
        ((user.crm != 'MONDAY' && user.crm != 'TGMONDAY') ||
          user.is_admin > 0) && (
          <Button color="white" isDisabled={isFetching} onClick={handleRefresh}>
            <Icons.Refresh />
            {t`Refresh`}
          </Button>
        )
      )}

      {isOpen && (
        <Editor
          title={t`Add an employee`}
          onSubmit={handleSubmit}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

Creating.propTypes = {
  setVisibleEmployeeList: PropTypes.func,
  getEmployeeList: PropTypes.func
};

Creating.defaultProps = {
  setVisibleEmployeeList: () => {},
  getEmployeeList: () => {}
};

export default Creating;
